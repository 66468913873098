

<template>
  <CRow id="summary" class="no-gutters">
    <CCol>
      <CCard class="h-100">
        <CCardHeader class="pt-1 pb-0 mb-0 card-header">
          <div id="truck-id"> TRUCK {{ truckId }} </div>
          <div id="day-info"> 
            <span class="date"> {{dayString}} </span> 
          </div>
        </CCardHeader>
        <CCardBody class="card-body">
          <CRow> 
            <CCol>
              <div id="job-count">
                <span class="number"> {{jobCounts}} </span>
                <label> Jobs in Total </label>
              </div>
              <CScoreCard hidden
                class="p-0"
                score="good"
                title="Safety Score"
              /> <!-- h_todo fix the scorecard-->
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol hidden>
      <CCard class="h-100">
        <CCardBody class="m-0 p-1"> 
            
            <SimpleLeafletMap class="h-100"
              :marker="center"
              :center="center"
              >
            </SimpleLeafletMap>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import { TruckDaySummary } from '@/lib/rule_lib/truck_day_summary';
import SimpleLeafletMap from "@/components/dashboard/SimpleLeafletMap"
import CScoreCard from '@/components/reports/CScoreCard';
export default {
  name: "SummaryReportScopeDay",
  components: {
    SimpleLeafletMap,
    CScoreCard
  },
  props: {
    summary: TruckDaySummary,
  },
  data() {
    return {
      
    };
  },
  computed:{
    deviceId () {
      return this.summary.deviceId;
    },

    truckId () {
      return this.summary.truckId;
    },

    dayString() {
      return this.summary.activeDate.toDateString();
    },

    jobCounts() {
      return this.summary.jobCounts;
    },
  },
  methods:{
  }
};
</script>

<style scoped>
#summary .card-header #truck-id {
  font-size: 30px;
  font-weight: 700;
}

#summary .card-body .number {
  font-size: 25px;
}

#summary .card-header .date {
  font-weight: 500;
}





</style>