<template>
  <CCard class="my-1 pt-1 mx-1">
    <CCardBody class="mt-0 pt-1">
      <CRow>
        <CCol col="5"> 
          <div class="text-muted">
            <label> Time In: </label>
            {{ startTimeString }} 
          </div>
          <div class="text-muted">
            <label> Time Out: </label>
            {{ endTimeString }} 
          </div>
        </CCol>
        <CCol col="3">
          <CButton size="" color="info" class="" @click="changeToJobView">
            Go to Job {{jobId}}
          </CButton>
        </CCol>
        <CCol col="4">
          <CScoreCard
            class="h-20 my-2"
            :score="score"
            title="Safety Score"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import CScoreCard from "@/components/reports/CScoreCard";
import { JobOperation } from "@/lib/rule_lib/job_operation";


export default {
  name: "ReportPerJobScopeDayCard",
  components: {
    CScoreCard
  },
  props: {
    job: JobOperation,
  },
  data() {
    return {};
  },
  computed: {
    jobId () {
      return this.job.id;
    },

    startTimeString () {
      return new Date(this.job.startTimestamp).toLocaleTimeString();
    },

    endTimeString () {
      return new Date(this.job.endTimestamp).toLocaleTimeString();
    },

    score () {
      return this.job.score;
    }
  },
  methods: {
    changeToJobView() {
      this.$emit("changeToJobView", this.job.uid);
    },
  },
};
</script>

<style scoped>

</style>
