var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCardBody",
    [
      _c(
        "CRow",
        { attrs: { id: "search-bar" } },
        [
          _c(
            "CCol",
            [
              _c("CInput", {
                attrs: {
                  placeholder: "Search for a Job",
                  value: _vm.searchValue,
                  lazy: "",
                  id: "search-input-box"
                },
                on: {
                  "update:value": function($event) {
                    _vm.searchValue = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c(
                          "CButton",
                          { attrs: { color: "info" } },
                          [
                            _c("CIcon", {
                              attrs: { name: "cil-magnifying-glass" }
                            }),
                            _vm._v(" Search ")
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        { attrs: { id: "table" } },
        [
          _c(
            "CCol",
            [
              _c("CDataTable", {
                attrs: {
                  items: _vm.jobItems,
                  fields: _vm.fields,
                  "items-per-page": 20,
                  hover: "",
                  pagination: "",
                  "table-filter": false,
                  "table-filter-value": _vm.searchValue,
                  cleaner: "",
                  "clickable-rows": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "id",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("ReportPerJobScopeDayCard", {
                          attrs: { job: item.data },
                          on: { changeToJobView: _vm.changeToJobView }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }