class TruckDaySummary {
  constructor(
    deviceId,
    truckId,
    jobOperations,
    date,
  ) {
    this.deviceId = deviceId;
    this.truckId = truckId;

    this.activeDate = date;

    this.jobCounts = jobOperations.length;
    this.subParJobCounts = jobOperations.filter(jobOperation => {
      return jobOperation.score != "Good" // h_todo: define sub-par
    }).length;

    this.startOfDay = jobOperations.reduce((min, curr) => {
      return Math.min(min, curr.startTimestamp);
    }, date - 1 + 864e5);

    this.endOfDay = jobOperations.reduce((max, curr) => {
      return Math.max(max, curr.endTimestamp);
    }, date - 1);

    let scoreNum = jobOperations.reduce((acc, curr) => {
      console.log('getNumberFromScore(curr.score)::: ', getNumberFromScore(curr.score));
      return acc + getNumberFromScore(curr.score);
    }, 0) / jobOperations.length;

    this.score = jobOperations.length? getScoreFromNumber(parseInt(scoreNum)) : "Not Available";

    // this.violationCounts = jobOperations.reduce((acc, jobOperation) => {
    //   return acc + 1; // h_todo: change 1 to job_violation
    // }, 0);
  }
}

function getNumberFromScore(status) {
  switch (status) {
    case "Good":
      return 4;
    case "Satisfactory":
      return 3;
    case "Poor":
      return 2;
    case "Very Poor":
      return 1;
    default:
      return 1;
  }
}

function getScoreFromNumber(status) {
  switch (status) {
    case 4:
      return "Good";
    case 3:
      return "Satisfactory";
    case 2:
      return "Poor";
    case 1:
      return "Very Poor";
    default:
      return "Unknown";
  }
}

export { TruckDaySummary };
