var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { lg: "12", color: "danger" } },
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { sm: "12" } },
                    [
                      _c(
                        "CCard",
                        { attrs: { "accent-color": _vm.accentColor } },
                        [
                          _c(
                            "CCardHeader",
                            {
                              staticClass: "standard-card-header",
                              attrs: {
                                color: _vm.backColor,
                                "text-color": "light"
                              }
                            },
                            [_c("span", [_vm._v(" Truck Daily Report ")])]
                          ),
                          _c(
                            "CCardBody",
                            [
                              _c(
                                "CRow",
                                { attrs: { id: "summary" } },
                                [
                                  _c(
                                    "CCol",
                                    [
                                      _vm.daySummary
                                        ? _c("SummaryReportScopeDay", {
                                            attrs: { summary: _vm.daySummary }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "CRow",
                                { attrs: { id: "list-table" } },
                                [
                                  _c(
                                    "CCol",
                                    [
                                      _c("ReportTableScopeDay", {
                                        attrs: {
                                          jobOperations: _vm.jobOperations
                                        },
                                        on: {
                                          changeToJobView: _vm.changeToJobView
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }