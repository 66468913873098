var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    { staticClass: "my-1 pt-1 mx-1" },
    [
      _c(
        "CCardBody",
        { staticClass: "mt-0 pt-1" },
        [
          _c(
            "CRow",
            [
              _c("CCol", { attrs: { col: "5" } }, [
                _c("div", { staticClass: "text-muted" }, [
                  _c("label", [_vm._v(" Time In: ")]),
                  _vm._v(" " + _vm._s(_vm.startTimeString) + " ")
                ]),
                _c("div", { staticClass: "text-muted" }, [
                  _c("label", [_vm._v(" Time Out: ")]),
                  _vm._v(" " + _vm._s(_vm.endTimeString) + " ")
                ])
              ]),
              _c(
                "CCol",
                { attrs: { col: "3" } },
                [
                  _c(
                    "CButton",
                    {
                      attrs: { size: "", color: "info" },
                      on: { click: _vm.changeToJobView }
                    },
                    [_vm._v(" Go to Job " + _vm._s(_vm.jobId) + " ")]
                  )
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { col: "4" } },
                [
                  _c("CScoreCard", {
                    staticClass: "h-20 my-2",
                    attrs: { score: _vm.score, title: "Safety Score" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }