<template>
  <CCardBody>
    <CRow id='search-bar'>
      <CCol>
        <CInput placeholder="Search for a Job"                 
          :value.sync="searchValue"
          lazy
          id="search-input-box"
        >
          <template #append>
            <CButton color="info">
              <CIcon name="cil-magnifying-glass" /> Search
            </CButton>
          </template>
        </CInput>
      </CCol>
    </CRow>
    <CRow id="table">
      <CCol>
        <CDataTable
          :items="jobItems"
          :fields="fields"
          :items-per-page="20"
          hover
          pagination
          :table-filter="false"
          :table-filter-value="searchValue"
          cleaner
          clickable-rows      
        >
          <template #id="{ item }">
            <ReportPerJobScopeDayCard
              @changeToJobView="changeToJobView"
              :job="item.data">
            </ReportPerJobScopeDayCard>
          </template>
        </CDataTable>
      </CCol>
    </CRow>
  </CCardBody>
</template>

<script>
import ReportPerJobScopeDayCard from '@/components/reports/ReportPerJobScopeDayCard';

export default {
  name: "ReportTableScopeDay",
  components:{
    ReportPerJobScopeDayCard,
  },
  props: {
    jobOperations: Array,
  },
  computed: {
    jobItems() {
      return this.jobOperations.map(jobOperation => {
        return {
          id: jobOperation.id, // so that this one is searchable
          data: jobOperation,
        }
      })
    }
  },
  data() {
    return {
      fields: [
        // even though we not showing the columns, but have to maintain id as column to make it searchable
        {key: 'id', label: ''},
      ],
      searchValue: null,
    };
  },
  methods: {
    changeToJobView(uid) {
      this.$emit("changeToJobView", uid);
    },
  },
};
</script>

<style scoped>
#search-input-box {
  width: 300px;
}
</style>