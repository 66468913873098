var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "no-gutters", attrs: { id: "summary" } },
    [
      _c(
        "CCol",
        [
          _c(
            "CCard",
            { staticClass: "h-100" },
            [
              _c("CCardHeader", { staticClass: "pt-1 pb-0 mb-0 card-header" }, [
                _c("div", { attrs: { id: "truck-id" } }, [
                  _vm._v(" TRUCK " + _vm._s(_vm.truckId) + " ")
                ]),
                _c("div", { attrs: { id: "day-info" } }, [
                  _c("span", { staticClass: "date" }, [
                    _vm._v(" " + _vm._s(_vm.dayString) + " ")
                  ])
                ])
              ]),
              _c(
                "CCardBody",
                { staticClass: "card-body" },
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        [
                          _c("div", { attrs: { id: "job-count" } }, [
                            _c("span", { staticClass: "number" }, [
                              _vm._v(" " + _vm._s(_vm.jobCounts) + " ")
                            ]),
                            _c("label", [_vm._v(" Jobs in Total ")])
                          ]),
                          _c("CScoreCard", {
                            staticClass: "p-0",
                            attrs: {
                              hidden: "",
                              score: "good",
                              title: "Safety Score"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { hidden: "" } },
        [
          _c(
            "CCard",
            { staticClass: "h-100" },
            [
              _c(
                "CCardBody",
                { staticClass: "m-0 p-1" },
                [
                  _c("SimpleLeafletMap", {
                    staticClass: "h-100",
                    attrs: { marker: _vm.center, center: _vm.center }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }