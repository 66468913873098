<template>
  <div>
    <CRow>
      <CCol lg="12" color="danger" class="">
        <CRow>
          <CCol sm="12">
            <CCard :accent-color="accentColor">
              <CCardHeader
                :color="backColor"
                text-color="light"
                class="standard-card-header"
              >
                <span> Truck Daily Report </span>
              </CCardHeader>
              <CCardBody>
                <CRow id="summary">
                  <CCol>
                    <SummaryReportScopeDay
                      v-if="daySummary"
                      :summary="daySummary"
                    />
                  </CCol>
                </CRow>

                <CRow id="list-table">
                  <CCol>
                    <ReportTableScopeDay
                      @changeToJobView="changeToJobView"
                      :jobOperations="jobOperations"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import ThemeMixin from "@/mixins/ThemeMixin";

import ReportTableScopeDay from "@/components/tables/ReportTableScopeDay";
import SummaryReportScopeDay from '@/components/reports/SummaryReportScopeDay';

import { TruckDaySummary } from '../../../lib/rule_lib/truck_day_summary';
import { JobOperation } from "@/lib/rule_lib/job_operation";

// import { MS_PER_DAY } from "@/components/time/constants"; // not sure why this line not working
const MS_PER_DAY = 86400000; 


function withinDateRange(time, startTime, endTime) {
  return time > startTime && time < endTime;
}

export default {
  name: "ReportListDayView",
  components: {
    ReportTableScopeDay,
    SummaryReportScopeDay,
  },
  data() {
    return {
      // activeUID: null,
    };
  },
  mixins: [ThemeMixin],
  computed: {
    filteringDeviceId () {
      return this.$route.query.device;
    },

    truckId () {
      if (this.jobOperations.length) {
        return this.jobOperations[0].truckId;
      } else {
        return "";
      }
    },
    
    dayTimestamp () {
      return Number(this.$route.query.date);
    },

    jobs() {
      let output = this.$store.getters.reports.map((item) => {
        return { ...item };
      }).filter(job => {
        if (this.filteringDeviceId) {
          return job.device == this.filteringDeviceId;
        }
        return true;
      }).filter(job => {
        if (this.dayTimestamp) {
          return withinDateRange(job.timestamp, this.dayTimestamp, this.dayTimestamp - 1 + MS_PER_DAY)
        }
      })
       
      return output;
    },

    jobOperations () {
      return this.jobs.map(job => {
        return new JobOperation({
          id: job.id,
          uid: job.uid,
          truckId: job.truck,
          deviceId: job.device,
          startTimestamp: job.start,
          endTimestamp: job.end,
          score: job.score,
        });
      })
    },

    daySummary () {
      return new TruckDaySummary(this.filteringDeviceId, this.truckId, this.jobOperations, new Date(this.dayTimestamp));
    },

    activeBranchId () {
      return this.$store.getters.selectedBranch? this.$store.getters.selectedBranch.branch_id : null;
    }
  },

  async created() {
    console.log(`reportSuperListView::created truck: ${this.filteringDeviceId} date: ${this.dayTimestamp}`);
    await this.loadReports();
  },

  // h_todo: to test if it is kept alive
  destroyed () {
    console.log(`reportSuperListView::destroyed truck: ${this.filteringDeviceId} date: ${this.dayTimestamp}`);
  },
  methods: {
    async refresh() {
      await this.loadReports();
    },
    async loadReports() {
      try {
        let params = {
          end: Date.now()
        };
        await this.$app.loadReports(params);
      } catch (err) {
        console.log(err);
      }
    },

    // setReportToActive (uid) {
    //   if (this.activeUID == uid) {
    //     this.activeUID = null;
    //   } else {
    //     this.activeUID = uid;
    //   }
    // },

    changeToJobView(id) {
      console.log('changeToJobView');
      this.$router.push({ 
        path: '/reports/view', 
        query: {
          id
        }
      })
    },
  },

  watch: {
    async activeBranchId (val) {
      await this.loadReports({
        branch_id: val, // h_todo: api allow branch filter
      }); 
    }
  }
};
</script>
